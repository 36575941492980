import React from 'react';
import './About.css';

import { Button, Heading } from '../../../components';
import { images } from '../../../constants'; 

import { FaBuilding, FaTools } from 'react-icons/fa';

  
 
const About = () => { 

  return ( 

    <section className='about'> 

      <div className='box-container'>

        {/* About images */}
        <div className='image'>
          <div className='sub-image1'><img src={images.about1} alt='About-Pic' /></div> {/* First image */}
          <div className='sub-image2'>
            <div className='experience-container'>
              <div className='experience'><span>35</span> years of experience</div> {/* Experience label */}
            </div>
            <img src={images.about2} alt='About-Pic' /> {/* Second image */}
          </div>
        </div>
 
        {/* About description */}
        <div className='content'> 
          <Heading title={'Making your vision become a reality'} subTitle={'know about us'} />
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur maiores deleniti magnam suscipit sint. Nihil esse molestias cupiditate sequi est earum, veritatis non perferendis? Alias, maiores repellendus. Incidunt, quod enim.</p> {/* Description */}
          <ul className='about-points'> 
            <li>
              <FaBuilding className='icon' /> {/* Building icon */}
              <div className='text'>
                <h5>construction</h5> {/* Construction title */}
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa est, quis unde dolore commodi aliquam quae.</p> {/* Construction description */}
              </div>
            </li>
            <li>
              <FaTools className='icon' />  {/* Tools icon */}
              <div className='text'>
                <h5>maintenance</h5> {/* Maintenance title */}
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsa est, quis unde dolore commodi aliquam quae.</p> {/* Maintenance description */}
              </div>
            </li>
          </ul>
          <Button link={'About-us'} title={'learn more'} /> {/* About-Us Button */}
        </div>

      </div>
      
    </section>
  );
}

export default About;