const pricing = [ 
    {   
        id: 1,  
        title: 'basis',
        content: 'perfect for small businesses',
        price: 50,
        list: [
            'interior design',
            'exterior design',
            'Building construction',
            'Renovation',
        ],
    }, 
    {  
        id: 2, 
        title: 'advanced',
        content: 'perfect for big business',
        price: 100,
        list: [
            'interior design',
            'exterior design',
            'Building construction',
            'Renovation',
        ],
    },
    {  
        id: 3, 
        title: 'enterprise',
        content: 'perfect for corporate',
        price: 200,
        list: [
            'interior design',
            'exterior design',
            'Building construction',
            'Renovation',
        ],
    },
];

export default pricing;