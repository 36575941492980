import React from 'react';
import './AboutUs.css';

import { PageTitle } from '../../../components';
import { About, Testimonials, Counter, Sponsors, WhyUs, Team } from '../../../sections';



const AboutUs = () => { 
  return ( 
    <>  
      {/* Page title */}
      <PageTitle title={'about us'} page={'about us'} />

      <section className='about-us'>
        
        {/* About section */}
        <About />

        {/* Counter section */}
        <Counter />

        {/* Why Us section */}
        <WhyUs />

        {/* Team section */}
        <Team />

        {/* Testimonials section */}
        <Testimonials />

        {/* Sponsors Section */}
        <Sponsors />

      </section>
    </>
  );
}

export default AboutUs;