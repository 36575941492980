import React from 'react';
import './Wishlist.css';

import { PageTitle, ShopTitle, EmptyCart } from '../../../components';

import { RiDeleteBin5Fill } from 'react-icons/ri';
import { BsCartPlusFill } from 'react-icons/bs';

  
   
const Wishlist = (props) => {

  // Destructuring props
  const { wishlist, removeFromWishlist, addToCart } = props; 

  return ( 
    <> 
    
      {/* Page title */}
      <PageTitle title={'wishlist'} page={'wishlist'} />

      <section className='wishlist'>

        {wishlist.length === 0 ? 
          (
            // Empty Wishlist message
            <EmptyCart title={'No items in the wishlist'} />
          ) : (
            <> 

              <div className='wishlist-container'>
                <div className='container'>

                  {/* Shop title */}
                  <ShopTitle titles={['product', 'Added On', 'price', 'action']} />

                  <div className='box-container'> 
                    {/* Render Wishlist items */} 
                    {wishlist.map((item) => {

                      return (
                        <div className='wishlist-item' key={item.id}>
                          <div className='box product'>
                            <img className='image' src={item.image} alt='' /> {/* Product Image */}
                            <div className='name'>{item.name}</div> {/* Product Name */}
                          </div> 
                          <div className='box date'>{item.dateAdded}</div> {/* Date when Item Added to Wishlist */}
                          <div className='box price'>${item.price}</div> {/* Product Price */}
                          <div className='box action'>
                            {/* Remove button */}
                            <button type='submit' onClick={() => removeFromWishlist(item)}>
                              <RiDeleteBin5Fill className='icon delete' /> 
                            </button>
                            {/* Add To Cart button */}
                            <button type='submit' onClick={() => addToCart(item)}>
                              <BsCartPlusFill className='icon cart' /> 
                            </button>
                          </div> 
                        </div>
                      );
                    })}
                  </div> 
                </div> 
              </div> 

            </>
          )}
      </section>
    </> 
  );
};

export default Wishlist;