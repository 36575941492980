import { images } from '.';
 
const services = [  
    {   
        id: 1, 
        image: images.service1, 
        title: 'interior design',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        paragraphs: [
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        ],
        gallery: [ 
            images.service1Gallery1,
            images.service1Gallery2,
        ],
        benefits: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'
            ],
            list: [
                'Save You Time And Money',
                'Creates high aesthetic quality',
                'Maximize the space',
                'Adds a sense of functionality',
                'Increase the Value of your Home'
            ]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },
    {
        id: 2,
        image: images.service2,
        title: 'exterior design',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        icon: 'star',
        paragraphs: [
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        ],
        gallery: [ 
            images.service2Gallery1,
            images.service2Gallery2,
        ],
        benefits: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'
            ],
            list: [
                'Save You Time And Money',
                'Creates high aesthetic quality',
                'Maximize the space',
                'Adds a sense of functionality',
                'Increase the Value of your Home'
            ]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },
    {
        id: 3,
        image: images.service3,
        title: 'architectural design',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        icon: 'headset',
        paragraphs: [
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        ],
        gallery: [ 
            images.service3Gallery1,
            images.service3Gallery2,
        ],
        benefits: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'
            ],
            list: [
                'Save You Time And Money',
                'Creates high aesthetic quality',
                'Maximize the space',
                'Adds a sense of functionality',
                'Increase the Value of your Home'
            ]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },
    {
        id: 4,
        image: images.service4,
        title: 'Building construction',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        icon: 'phone',
        paragraphs: [
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        ],
        gallery: [ 
            images.service4Gallery1,
            images.service4Gallery2,
        ],
        benefits: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'
            ],
            list: [
                'Save You Time And Money',
                'Creates high aesthetic quality',
                'Maximize the space',
                'Adds a sense of functionality',
                'Increase the Value of your Home'
            ]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },
    {
        id: 5,
        image: images.service5,
        title: 'road repair',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        icon: 'calendar',
        paragraphs: [
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        ],
        gallery: [ 
            images.service5Gallery1,
            images.service5Gallery2,
        ],
        benefits: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'
            ],
            list: [
                'Save You Time And Money',
                'Creates high aesthetic quality',
                'Maximize the space',
                'Adds a sense of functionality',
                'Increase the Value of your Home'
            ]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },
    {
        id: 6,
        image: images.service6,
        title: 'Renovation',
        content: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Consequatur Possimus Assumenda Dolores Odit.',
        icon: 'thumbsUp',
        paragraphs: [
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore repudiandae aut eum rem praesentium doloremque quos perferendis ipsa sed placeat fugiat minima tenetur facilis, similique, esse molestias harum. Illo, obcaecati?',
            'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        ],
        gallery: [ 
            images.service6Gallery1,
            images.service6Gallery2,
        ],
        benefits: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.'
            ],
            list: [
                'Save You Time And Money',
                'Creates high aesthetic quality',
                'Maximize the space',
                'Adds a sense of functionality',
                'Increase the Value of your Home'
            ]
        },
        workFlow: {
            paragraphs: [
                'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat.'
            ],
            workItems: {
                planning: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                design: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
                construct: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
            },
        },
        faqs: [
            { 
                id: 1, 
                heading: 'How do we charge for projects?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            }, 
            { 
                id: 2,
                heading: 'Do we offer free in-person estimates?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 3, 
                heading: 'What is our service area?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
            {
                id: 4,
                heading: 'do we hire subcontractors?',
                content: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minus, laboriosam non eligendi reiciendis quis laborum exercitationem voluptatibus autem harum nihil nisi sed mollitia, quam blanditiis architecto cumque? Sit, voluptate maiores.',
            },
        ]
    },
 
];

export default services;