import React from 'react';
import './Footer.css';

import { Logo, Heading } from '../../components';
import { contactContent, services } from '../../constants';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaAngleDoubleRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';


  
const Footer = () => {   
    
  return (
    <footer className='footer'>
      
      <div className='box-container'>

        {/* About Section */}
        <div className='footer-item'> 
          <Logo />
          <p>We Provide Quality Construction For Every Project. Your Satisfaction Is Our Goal. Your Vision Is Our Business. We Build The House. You Make It A Home.</p>
          <div className='social'>
            <a href="/#"><FaFacebookF className='icon' /></a>
            <a href="/#"><FaTwitter className='icon' /></a>
            <a href="/#"><FaInstagram className='icon' /></a>
            <a href="/#"><FaLinkedin className='icon' /></a>
          </div>
        </div> 

        {/* Services Links Section */}
        <div className='footer-item'> 

          <Heading title={'our services'} />

          <div className='info links'> 
           
            {services.map(service => {
              {/* Link to the individual service page */}
              return <p key={service.id}><FaAngleDoubleRight className='icon' /> <Link
                to={{ 
                  pathname: '/Services/' + service.title,
                }}
                location = {{service}}
                state= {service}>{service.title}  {/* Service Title */}
              </Link>
              </p>
            })}

          </div>
        </div> 

        {/* Useful Links Section */}
        <div className='footer-item'> 

          <Heading title={'useful links'} />

          <div className='info links'> 
              <p><FaAngleDoubleRight className='icon' /><Link to='/about-us'>about</Link></p>
              <p><FaAngleDoubleRight className='icon' /><Link to='/Services'>Services</Link></p>
              <p><FaAngleDoubleRight className='icon' /><Link to='/Projects'>Projects</Link></p>
              <p><FaAngleDoubleRight className='icon' /><Link to='/Blog-list'>blogs</Link></p>
              <p><FaAngleDoubleRight className='icon' /><Link to='/Shop-list'>shop</Link></p>
              <p><FaAngleDoubleRight className='icon' /><Link to='/Contact-us'>contact</Link></p>
          </div>

        </div> 
  
        {/* Contact Info Section */}
        <div className='footer-item'>  

          <Heading title={'contact info'} />

          <div className='info connect'> 
            {contactContent.map((contact) => {
              return (contact.content).map((value, index) => {
                return <p key={index}><span className='icon'>{contact.icon}</span>{value}</p>
              })
            })}
          </div>
 
        </div>

      </div>
 
      <div className='content'>
        <p>designed by <span>AS_Designs</span> | all rights reserved</p>
      </div>
      
    </footer>
  )
}

export default Footer;