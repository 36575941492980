import team from './team';
import services from './services';
import projects from './projects';
import products from './products';
import blogs from './blogs'; 



const navbar = [  
    {
        id: 1,
        mainLink : {link: '/', title: 'home'},
        subLink: [],
    },
    {
        id: 2,
        mainLink : {link: '#', title: 'about'},
        subLink: [
            {id: 1, link: '/About-us', title: 'about us'},
            {id: 2, link: '/Team', title: 'team'},
            {id: 3, link: '/Team/TeamSingle', title: 'team single', state: team[0]},
            {id: 4, link: '/Testimonials', title: 'testimonials'}, 
            {id: 5, link: '/Faqs', title: 'faqs'},
        ],
    },
    {
        id: 3,
        mainLink : {link: '#', title: 'pages'},
        subLink: [
            {id: 1, link: '/Appointment', title: 'Appointment'},
            {id: 2, link: '/Pricing', title: 'Pricing'},
            {id: 3, link: '/Login', title: 'login'},
            {id: 4, link: '/Register', title: 'register'},
            {id: 5, link: '/Forgot-pwd', title: 'forgot Password'},
        ],
    },
    {
        id: 4, 
        mainLink : {link: '#', title: 'Service'},
        subLink: [
            {id: 1, link: '/Services', title: 'Services'},
            {id: 2, link: '/Services/ServiceSingle', title: 'Service Single', state: services[0]}, 
        ],
    }, 
    {
        id: 5, 
        mainLink : {link: '#', title: 'Project'},
        subLink: [
            {id: 1, link: '/Projects', title: 'Projects'},
            {id: 2, link: '/Projects/ProjectSingle', title: 'Project Single', state: projects[0]},
        ],
    }, 
    {
        id: 6,
        mainLink : {link: '#', title: 'blog'},
        subLink: [
            {id: 1, link: '/Blog-grid', title: 'blog grid'},
            {id: 2, link: '/Blog-list', title: 'blog list'},
            {id: 3, link: '/Blogs/Blog-Single', title: 'blog single', state: blogs[0]},
        ],
    },
    {
        id: 7,
        mainLink : {link: '#', title: 'shop'},
        subLink: [
            {id: 1, link: '/Shop-grid', title: 'shop grid'},
            {id: 2, link: '/Shop-list', title: 'shop list'},
            {id: 3, link: '/Shop/Product-Single', title: 'product single', state: products[0]},
            {id: 4, link: '/Wishlist', title: 'wishlist'},
            {id: 5, link: '/Cart', title: 'cart'},
            {id: 6, link: '/Checkout', title: 'checkout'},
        ],
    },
    {
        id: 8,
        mainLink : {link: '/Contact-us', title: 'contact'},
        subLink: [],
    },
];

export default navbar;