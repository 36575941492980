import React, { useEffect, useState } from 'react';
import './Project.css';

import { ProjectItem, ProjectTabs } from '../../components';
import { projects } from '../../constants'; 
 
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
 
 
 
const Project = () => {
 
  // State
  const [category, setCategory] = useState('all');
  const [filteredImages, setFilteredImages] = useState([]);

  // State to track the index of the currently displayed image
  const [index, setIndex] = useState(-1);

  // Handlers for lightbox navigation
  const currentImage = projects[index];
  const nextIndex = (index + 1) % projects.length;
  const nextImage = projects[nextIndex] || currentImage;
  const prevIndex = (index + projects.length - 1) % projects.length;
  const prevImage = projects[prevIndex] || currentImage;

  const openLightbox = (index) => setIndex(index);
  const closeLightbox = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  // Get unique categories from the projects
  const categories = ['all', ...new Set(projects.map(item => item.category))];
  
  // Filter images based on selected category
  useEffect(() => {
    if (category === 'all') { 
      setFilteredImages(projects);
    } else {
      const filtered = projects.filter((image) =>
        image.category.toLowerCase() === category.toLowerCase()
      );
      setFilteredImages(filtered);
    }
  }, [category]);

  
  return (
    <div className='projects'>

      {/* Render project tabs buttons */} 
      <ul className='controls'>
        {categories.map((item) => { 
          return(
            <ProjectTabs
            key={item} 
            id={item} 
            title={item}
            setSelected={setCategory}
            active={category == item ? true: false } 
            />
          ) 
        })
        }
      </ul>  

      <div className='box-container'>
        
        {/* Render project items */}
        {filteredImages.map((item, index) => {
          return (
            <ProjectItem
              key={item.id}
              id={index}
              image={item.image}
              title={item.title}
              category={item.category}
              project={item}
              popup={openLightbox}
            />
          );
        })}

        {/* Render lightbox when index is not -1 */}
        {index !== -1 && (
          <Lightbox
            mainSrc={currentImage.image}
            imageTitle={currentImage.title}
            mainSrcThumbnail={currentImage.image}
            nextSrc={nextImage.image}
            nextSrcThumbnail={nextImage.image}
            prevSrc={prevImage.image}
            prevSrcThumbnail={prevImage.image}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}

      </div>

    </div>
  );
};

export default Project; 