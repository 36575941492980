import { images } from "../constants";

const testimonials = [  
    {  
        id: 1,
        image: images.testimonial1, 
        name: 'Robert Anderson', 
        title: 'CEO, Founder',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    }, 
    { 
        id: 2,
        image: images.testimonial2, 
        name: 'Lesley Murdoch', 
        title: 'Customer',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    },
    { 
        id: 3,
        image: images.testimonial3, 
        name: 'Andrew Roberts', 
        title: 'builder',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    },    
    { 
        id: 4,
        image: images.testimonial4, 
        name: 'Nancy Williams', 
        title: 'constructor',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    },
];

export default testimonials;