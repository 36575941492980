import React from 'react';

import { PageTitle } from '../../../components';
import { Pricing } from '../../../sections';
 


const PricingPage = () => {  
  return (   
    <> 

      {/* Page Title */}
      <PageTitle title={'pricing'} page={'pricing'} />
      
      {/* Pricing Section */}
      <Pricing />
      
    </>
  )
}

export default PricingPage;