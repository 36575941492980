import React from 'react';
import './Product.css';

import { calculateAverageRating } from '../../utils';

import { FaStar } from 'react-icons/fa';
import { BsCartPlusFill, BsHeart, BsEye } from 'react-icons/bs';

import { Link } from 'react-router-dom';

     
 
const Product = (props) => {

  // Destructuring props
  const { image, content, name, disprice, price, addToCart, addToWishlist, product } = props;

  // Calculate the average rating for the product
  const averageRating = calculateAverageRating(product);

  return ( 

    <div className='product-item'>

      <div className='image'>

        <div className='options'> 
          <Link to='/cart' onClick={() => addToCart(product)}><BsCartPlusFill className='icon' /></Link> {/* Cart button */}
          <Link to='/wishlist' onClick={() => addToWishlist(product)}><BsHeart className='icon' /></Link> {/* Wishlist button */}
          <Link to={{ pathname: '/Shop/' + name }} location={{ product }} state={product}><BsEye className='icon' /></Link> {/* Product Details Page button */}
        </div>
        
        <img src={image} alt={name} /> {/* Product image */}

      </div> 
 
      <div className='content'>
        
        <Link  
          to={{
            pathname: '/Shop/' + name,
          }}
          location={{ product }}
          state={product}><h3>{name}</h3> {/* Product name */}
        </Link> 

        <div className='price'>
          <p>${disprice} <span>${price}</span></p>{/* Discounted price and original price */}
        </div>

        <p>{content}</p> {/* Product description */}

        {/* Star rating */}
        <div className='rating'>
          <div className='icon'>
            <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
          </div>
          {/* Display average rating and number of comments */}
          {product.comments.length > 0 ? (
            <h5> {averageRating} ({product.comments.length}) </h5>
          ) : (
            <h5>Not rated</h5>
          )}
        </div>
        
      </div>

    </div>
  );
};

export default Product;