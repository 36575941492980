import React from 'react';
import './PreLoader.css';

import { images } from '../../constants';
 
  
 
const PreLoader = () => {
  
  return ( 
 
    /* Pre-loader Container */
    <div className='pre-loader'>
      {/* Loader Container */}
      <div id="loader">
        <img src={images.logo} alt="" /> {/* Displaying the logo image */}
      </div>
    </div>

  );
};

export default PreLoader;