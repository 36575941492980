import React, { useEffect, useState } from 'react';

import { Header, Footer, ScrollTop, ScrollToTop, PreLoader } from './components';
import { HomePage, AboutUs, TestimonialsPage, Faqs, BlogGrid, BlogList, BlogSingle, Login, Register, ForgotPwd, ShopGrid, ShopList, Cart, Checkout, Contact, ProductSingle, Wishlist, ServiceSingle, ProjectPage, ProjectSingle, TeamSingle, TeamPage, ServicePage, AppointmentPage, PricingPage } from './sections';
import { useCart, useWishlist } from './utils';

import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";


 
const App = () => {

  // Use the useCart hook to manage the cart state
  const { cart, setCart, addToCart, removeFromCart, removeCompletely } = useCart(); 

  // Use the useWishlist hook to manage the wishlist state
  const { wishlist, addToWishlist, removeFromWishlist } = useWishlist(); 

  const [loading, setLoading] = useState(true);

  const location = useLocation();


  useEffect(() => {
    setLoading(true);
    // Simulate a loading delay
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, [location]);


  return ( 
    
    <div className='App'>

      {/* Display preloader while loading */}
      {loading && <PreLoader />}

      {/* Component to Scroll to Top on Route Change */}
      <ScrollToTop />
      {/* Render the header component and pass the cart state */}
      <Header cart={cart} />

      <Routes>

        {/*Home*/}
        <Route path='/' element={<HomePage />} />

        {/*About*/}
        <Route path='/About-us' element={<AboutUs />} />
        <Route path='/Team' element={<TeamPage />} />  
        <Route path='/Team/:id' element={<TeamSingle />} />    
        <Route path='/Team/TeamSingle' element={<TeamSingle />} /> 
        <Route path='/Testimonials' element={<TestimonialsPage />} /> 
        <Route path='/Faqs' element={<Faqs />} />    

        {/*Pages*/}
        <Route path='/Appointment' element={<AppointmentPage />} />   
        <Route path='/Pricing' element={<PricingPage />} />    
        <Route path='/Login' element={<Login />} />  
        <Route path='/Register' element={<Register />} /> 
        <Route path='/Forgot-pwd' element={<ForgotPwd />} /> 

        {/*Service*/}
        <Route path='/Services' element={<ServicePage />} />     
        <Route path='/Services/:id' element={<ServiceSingle />} />    
        <Route path='/Services/ServiceSingle' element={<ServiceSingle />} /> 

        {/*Project*/}
        <Route path='/Projects' element={<ProjectPage />} />   
        <Route path='/Projects/:id' element={<ProjectSingle />} />   
        <Route path='/Projects/FixtureSingle' element={<ProjectSingle />} /> 
  
        {/*Blogs*/}
        <Route path='/Blog-grid' element={<BlogGrid />} /> 
        <Route path='/Blogs/category/:category' element={<BlogList />} /> 
        <Route path='/Blogs/tag/:tag' element={<BlogList />} /> 
        <Route path='/Blogs/search/:search' element={<BlogList />} /> 
        <Route path='/Blog-list' element={<BlogList />} /> 
        <Route path='/Blogs/:id' element={<BlogSingle  />} />
        <Route path='/Blogs/Blog-Single' element={<BlogSingle />} />

        {/*Shop*/}
        <Route path='/Shop-grid' element={<ShopGrid addToCart={addToCart} addToWishlist={addToWishlist} />} />  
        <Route path='/Shop/category/:category' element={<ShopList addToCart={addToCart} addToWishlist={addToWishlist} />} /> 
        <Route path='/Shop/tag/:tag' element={<ShopList addToCart={addToCart} addToWishlist={addToWishlist} />} /> 
        <Route path='/Shop-list' element={<ShopList addToCart={addToCart} addToWishlist={addToWishlist} />} />
        <Route path='/Shop/:id' element={<ProductSingle cart={cart} setCart={setCart} addToCart={addToCart} />} />
        <Route path='/Shop/Product-Single' element={<ProductSingle cart={cart} setCart={setCart} addToCart={addToCart} />} /> 
        <Route path='/Wishlist' element={<Wishlist wishlist={wishlist} removeFromWishlist={removeFromWishlist} addToCart={addToCart} />} />
        <Route path='/Cart' element={<Cart cart={cart} removeFromCart={removeFromCart} addToCart={addToCart} removeCompletely={removeCompletely} />} />
        <Route path='/Checkout' element={<Checkout cart={cart} />} />
 
        {/*Contact*/}
        <Route path='/contact-us' element={<Contact />} /> 

      </Routes>

      {/* Render the footer component */}
      <Footer />
      {/* Scroll to top button */}
      <ScrollTop />
      
    </div>
  )
}

export default App;
