import React from 'react';
import './Sponsors.css';

import { Heading } from '../../../components';
import { sponsors } from '../../../constants';

// Import Swiper core and required modules
import { Autoplay } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles 
import 'swiper/css';    

  
 
const Sponsors = () => {

  return (
    <section className='sponsors'>

      {/* Section heading */}
      <Heading title={'our sponsors'} subTitle={'our amazing sponsors'} />

      <div className='sponsor-slider'>

        <Swiper
          // Install Swiper modules
          modules={[Autoplay]}
          spaceBetween={20}
          loop={true}
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            450: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 5,
            },
          }} 
        >
        {/* Map over the sponsors array to render each sponsor */}
        {sponsors.map((sponsor, index) => {
            return (
              /* Render the sponsor image */
              <SwiperSlide className='sponsor-item' key={index}><img src={sponsor} alt='' /></SwiperSlide>
            )
          })
        }
        </Swiper>
      </div>
    </section>
  );
}

export default Sponsors;