import React from 'react';
import './TeamSingle.css';

import { PageTitle } from '../../../components';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

import { useLocation } from 'react-router-dom';
 
  

const TeamSingle = () => {  
 
  const location = useLocation();  
  const team = location.state;

  const accounts = team.accounts;
  const skillsContent = team.skills;

  // Get the total count of Skills
  const skillsCount = skillsContent.length;

  // Calculate the halfway point of the Skills
  const halfCount = Math.ceil(skillsCount / 2);

  // Split the Skills into two arrays
  const skillsCount1 = skillsContent.slice(0, halfCount); // First half of Skills
  const skillsCount2 = skillsContent.slice(halfCount); // Second half of Skills


  return ( 
    <>

      {/* Page title */}
      <PageTitle title={'team single'} page={'team single'} />

      <section className='team-single'>      

        <div className='box-container'>

          {/* Team Member Introduction */}
          <div className='intro'>

            {/* Team Member Image */}
            <div className='image'>
              <img src={team.image} alt='Blog' />
            </div>

            {/* Social media icons */}
            <div className='icon-container'>
              <a className='icon' href={accounts.facebook}><FaFacebookF /></a> {/* Facebook Icon */}
              <a className='icon' href={accounts.linkedin}><FaLinkedin /></a> {/* LinkedIn Icon */}
              <a className='icon' href={accounts.twitter}><FaTwitter /></a> {/* Twitter Icon */}
              <a className='icon' href={accounts.instagram}><FaInstagram /></a> {/* Instagram Icon */}
            </div>

          </div>

          {/* Team Member Details */}
          <div className='information'>

            <h3>{team.name}</h3> {/* Team Member Name */}
            <p>{team.title}</p> {/* Team Member Title */}

            {/* Team Member About */}
            <div className='team-about'>
              <h4>Biography</h4> {/* Biography Title */}
              <div className='text'>{team.biography}</div> {/* Team Member Biography */}
            </div>

          </div> 

        </div>

        {/* Team Skills */}
        <div className='team-skills'>

          <h4>Skills</h4>

          {/* Skills Container */}
          <div className='skills'>

            {/* First set of skills */}
            <div className='skill-item'>
              {/* Rendering skills from the first set */}
              {skillsCount1.map(skill => {
                return(
                  /* skill Box */
                  <div className='box' key={skill.id}> 
                    <h3>{skill.title}<span> {skill.progress}% </span> </h3> {/* Skill title and progress percentage */}
                    <div className='progress-bar'> <span style={{ width: `${skill.progress}%` }}></span> </div> {/* Progress bar */}
                  </div>
                )
              })}
            </div>

            {/* Second set of skills */}
            <div className='skill-item'>
              {/* Rendering skills from the second set */}
              {skillsCount2.map(skill => {
                return(
                  /* skill Box */
                  <div className='box' key={skill.id}>
                    <h3>{skill.title}<span> {skill.progress}% </span> </h3> {/* Skill title and progress percentage */}
                    <div className='progress-bar'> <span style={{ width: `${skill.progress}%` }}></span> </div> {/* Progress bar */}
                  </div>
                )
              })}
            </div>
          
          </div>

        </div>

      </section>
    </>
  )
  
}

export default TeamSingle;