import React from 'react';
import './Blog.css';

import { FaUserCircle } from 'react-icons/fa';

import { Link } from 'react-router-dom';
 

   
const Blog = (props) => {

  // Destructuring props
  const { id, image, date, heading, blog, admin, category } = props;

  return (

    <div className='blog-item' key={id}>

      {/* Blog image Container */}
      <div className='image'>
        <img src={image} alt='Blog' /> {/* Blog image
         */}
        <div className='date'>{date.substring(0, date.indexOf(","))}</div> {/* Blog publication date */}
      </div>

      <div className='content'>
 
        {/* Blog category */}
        <Link
          className='category'
          key={blog.id}
          to={`/Blogs/category/${category}`}>
          {category}
        </Link> 
        
        {/* Link to the individual blog post */}
        <Link
          to={{
            pathname: '/Blogs/' + heading,
          }}
          state={blog}
          className='main-heading' >
          {heading} {/* Blog heading */}
        </Link>

        {/* Blog Admin */}
        <h3><FaUserCircle className='icon' /><span>By {admin}</span></h3> 
        
      </div>
      
    </div>
  );
};

export default Blog;