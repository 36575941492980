import { FaCouch, FaHome, FaPencilRuler, FaBuilding, FaRoad, FaPaintRoller } from 'react-icons/fa';


// Function to render the appropriate icon based on the service name
export const renderIcon = (serviceName) => {

  // Convert the service name to lowercase
  const lowercaseServiceName = serviceName.toLowerCase();

  // Check the lowercase service name and return the corresponding icon component
  switch (lowercaseServiceName) {
    case 'interior design':
      return <FaCouch />; // Return the FaCouch icon for interior design
    case 'exterior design':
      return <FaHome />; // Return the FaHome icon for exterior design
    case 'architectural design':
      return <FaPencilRuler />; // Return the FaPencilRuler icon for architectural design
    case 'building construction':
      return <FaBuilding />; // Return the FaBuilding icon for building construction
    case 'road repair':
      return <FaRoad />; // Return the FaRoad icon for road repair
    case 'renovation':
      return <FaPaintRoller />; // Return the FaPaintRoller icon for renovation
    default:
      return null; // If no matching service name is found, return null
  }

};