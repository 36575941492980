import React, { useEffect, useState } from 'react';
import './MobileNavbar.css';

import { NavbarItem } from '../../components';
import { navbar } from '../../constants';
 
 
   
/* Mobile Navbar container */
const MobileNavbar = (props) => { 
 
  // Destructuring props 
  const { isOpen } = props;
 
  // State to manage the active nav link
  const [activeNavLinkId, setActiveNavLinkId] = useState(null);

  // Function to handle nav link click event
  const handleNavLinkClick = (id) => {
    setActiveNavLinkId((prevId) => (prevId === id ? null : id));
  };
 
  // Reset the active nav link when the mobile navbar menu is closed
  useEffect(() => {
    if (!isOpen) {
      setActiveNavLinkId(null); 
    }
  }, [isOpen]);

  return (
    
    <div className={`mobile-menu ${isOpen ? 'active' : ''}`}>

      {/* Navigation items */}  
      <nav className='mobile-navbar'>
        {navbar.map((item) => {
          return (
            <NavbarItem key={item.id} id={item.id} {...item}               
            isActive={item.id === activeNavLinkId}
            onNavLinkClick={handleNavLinkClick} />
          )
        })}
      </nav>
      
    </div>
  )
}

export default MobileNavbar;