import React, { useState } from 'react';
import './Appointment.css';

import { Heading } from '../../../components';
 
  
 
const Appointment = () => {  

  // State variables for input types
  const [dateType, setDateType] = useState('text');
  const [timeType, setTimeType] = useState('text');
 
  // Form submission handler
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Reset the form fields
    event.target.reset();
  };

  return (
    <section className='appointment linear-bg' id="appointment">

      <div className='box-container'>

        {/* Heading for the Appointment Form */}
        <Heading title={'appointment booking'} subTitle={'get free consultation'} />

        {/* Appointment Form */}
        <form className='appointment-form' onSubmit={handleSubmit}>

          {/* Input fields for first name and last name */}
          <div className='input-box'>
            <input type="text" name="first-name" className='box' id="first-name" placeholder="first name" required />
            <input type="text" name="last-name" className='box' id="last-name" placeholder="last name" required />
          </div>

          {/* Input fields for email and phone number */}
          <div className='input-box'>
            <input type="email" name="email" className='box' id="email" placeholder="email" required />
            <input type="number" name="phone-no" className='box' id="phone-no" placeholder="number" required />
          </div>

          {/* Input field for address */}
          <input type="text" name="address" className='box' id="address" placeholder="address" required />  
          
          {/* Input fields for city and zip */}
          <div className='input-box'>
            <input type="text" name="city" placeholder="town/city" id="city" className='box' />
            <input type="text" name="zip" placeholder="postcode/ZIP" id="zip" className='box' />
          </div>

          {/* Input fields for date and time */}
          <div className="input-box">
            <input type={dateType} name="date" placeholder="date" id="date" className="box" onFocus={() => setDateType('date')} onBlur={() => setDateType('text')} />
            <input type={timeType} name="time" placeholder="time" id="time" className="box" onFocus={() => setTimeType('time')} onBlur={() => setTimeType('text')} />
          </div>

          {/* Input field for additional comments */}
          <textarea cols="30" rows="10" name="comment" className='box' id="comment" placeholder="message"></textarea>

          {/* Submit Button */}
          <button type='submit' name='submit' id='submit' className='btn'>submit now</button>

          {/* Alert message */}
          <span className='alert' id="msg"></span>
        
        </form>

      </div>

    </section> 
  );
}

export default Appointment;