import { images } from '.'; 
 
const projects = [  
    {
        id: 1,
        image: images.project1,
        title: 'Office interior',
        date: '11 june, 2023',
        client: 'tech company',
        category: 'interior',
        duration: '1 month',
        location: 'karachi, pakistan',
        website: 'www.website.com',
        budget: '$1000',
        architect: 'joe root',
        important: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis.',
        paragraphs: [
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
        ],
        gallery: [   
            images.project1Gallery1,
            images.project1Gallery2,
        ],
        challenges: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Poor space utilisation',
                'Noisy open plan spaces',
                'No reflection spaces and quiet areas',
                'Lack of culture and community',
                'Not enough workspaces'
            ],
        } ,
        results: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Workplace consultancy and space analysis',
                'Office layout and adjacency planning',
                'Dedicated sanctuary spaces'
            ],
        },
    },        
    {
        id: 2,
        image: images.project2,
        title: 'modern villa',
        date: '11 june, 2023',
        client: 'tech company',
        category: 'exterior',
        duration: '1 month',
        location: 'karachi, pakistan',
        website: 'www.website.com',
        budget: '$1000',
        architect: 'joe root',
        important: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis.',
        paragraphs: [
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
        ],
        gallery: [   
            images.project2Gallery1,
            images.project2Gallery2,
        ],
        challenges: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Poor space utilisation',
                'Noisy open plan spaces',
                'No reflection spaces and quiet areas',
                'Lack of culture and community',
                'Not enough workspaces'
            ],
        } ,
        results: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Workplace consultancy and space analysis',
                'Office layout and adjacency planning',
                'Dedicated sanctuary spaces'
            ],
        },
    },
    {
        id: 3,
        image: images.project3,
        title: 'factory construction',
        date: '11 june, 2023',
        client: 'tech company',
        category: 'construction',
        duration: '1 month',
        location: 'karachi, pakistan',
        website: 'www.website.com',
        budget: '$1000',
        architect: 'joe root',
        important: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis.',
        paragraphs: [
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
        ],
        gallery: [   
            images.project3Gallery1,
            images.project3Gallery2,
        ],
        challenges: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Poor space utilisation',
                'Noisy open plan spaces',
                'No reflection spaces and quiet areas',
                'Lack of culture and community',
                'Not enough workspaces'
            ],
        } ,
        results: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Workplace consultancy and space analysis',
                'Office layout and adjacency planning',
                'Dedicated sanctuary spaces'
            ],
        },
    },
    {
        id: 4,
        image: images.project4,
        title: 'Home renovation',
        date: '11 june, 2023',
        client: 'tech company',
        category: 'Renovation',
        duration: '1 month',
        location: 'karachi, pakistan',
        website: 'www.website.com',
        budget: '$1000',
        architect: 'joe root',
        important: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis.',
        paragraphs: [
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
        ],
        gallery: [   
            images.project4Gallery1,
            images.project4Gallery2,
        ],
        challenges: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Poor space utilisation',
                'Noisy open plan spaces',
                'No reflection spaces and quiet areas',
                'Lack of culture and community',
                'Not enough workspaces'
            ],
        } ,
        results: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Workplace consultancy and space analysis',
                'Office layout and adjacency planning',
                'Dedicated sanctuary spaces'
            ],
        },
    },
    {
        id: 5,
        image: images.project5,
        title: 'Hotel interior',
        date: '11 june, 2023',
        client: 'tech company',
        category: 'interior',
        duration: '1 month',
        location: 'karachi, pakistan',
        website: 'www.website.com',
        budget: '$1000',
        architect: 'joe root',
        important: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis.',
        paragraphs: [
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
        ],
        gallery: [   
            images.project5Gallery1,
            images.project5Gallery2,
        ],
        challenges: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Poor space utilisation',
                'Noisy open plan spaces',
                'No reflection spaces and quiet areas',
                'Lack of culture and community',
                'Not enough workspaces'
            ],
        } ,
        results: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Workplace consultancy and space analysis',
                'Office layout and adjacency planning',
                'Dedicated sanctuary spaces'
            ],
        },
    },
    {
        id: 6,
        image: images.project6,
        title: 'apartment exterior',
        date: '11 june, 2023',
        client: 'tech company',
        category: 'exterior',
        duration: '1 month',
        location: 'karachi, pakistan',
        website: 'www.website.com',
        budget: '$1000',
        architect: 'joe root',
        important: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis.',
        paragraphs: [
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
        ],
        gallery: [   
            images.project6Gallery1,
            images.project6Gallery2,
        ],
        challenges: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Poor space utilisation',
                'Noisy open plan spaces',
                'No reflection spaces and quiet areas',
                'Lack of culture and community',
                'Not enough workspaces'
            ],
        } ,
        results: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Workplace consultancy and space analysis',
                'Office layout and adjacency planning',
                'Dedicated sanctuary spaces'
            ],
        },
    },
    {
        id: 7,
        image: images.project7,
        title: 'road construction',
        date: '11 june, 2023',
        client: 'tech company',
        category: 'construction',
        duration: '1 month',
        location: 'karachi, pakistan',
        website: 'www.website.com',
        budget: '$1000',
        architect: 'joe root',
        important: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis.',
        paragraphs: [
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
        ],
        gallery: [   
            images.project7Gallery1,
            images.project7Gallery2,
        ],
        challenges: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Poor space utilisation',
                'Noisy open plan spaces',
                'No reflection spaces and quiet areas',
                'Lack of culture and community',
                'Not enough workspaces'
            ],
        } ,
        results: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Workplace consultancy and space analysis',
                'Office layout and adjacency planning',
                'Dedicated sanctuary spaces'
            ],
        },
    },
    {
        id: 8,
        image: images.project8,
        title: 'Office renovation',
        date: '11 june, 2023',
        client: 'tech company',
        category: 'Renovation',
        duration: '1 month',
        location: 'karachi, pakistan',
        website: 'www.website.com',
        budget: '$1000',
        architect: 'joe root',
        important: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis.',
        paragraphs: [
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
        ],
        gallery: [   
            images.project8Gallery1,
            images.project8Gallery2,
        ],
        challenges: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Poor space utilisation',
                'Noisy open plan spaces',
                'No reflection spaces and quiet areas',
                'Lack of culture and community',
                'Not enough workspaces'
            ],
        } ,
        results: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Workplace consultancy and space analysis',
                'Office layout and adjacency planning',
                'Dedicated sanctuary spaces'
            ],
        },
    },
    {
        id: 9,
        image: images.project9,
        title: 'resort interior',
        date: '11 june, 2023',
        client: 'tech company',
        category: 'interior',
        duration: '1 month',
        location: 'karachi, pakistan',
        website: 'www.website.com',
        budget: '$1000',
        architect: 'joe root',
        important: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis.',
        paragraphs: [
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
        ],
        gallery: [   
            images.project9Gallery1,
            images.project9Gallery2,
        ],
        challenges: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Poor space utilisation',
                'Noisy open plan spaces',
                'No reflection spaces and quiet areas',
                'Lack of culture and community',
                'Not enough workspaces'
            ],
        } ,
        results: {
            intro: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            points: [ 
                'Workplace consultancy and space analysis',
                'Office layout and adjacency planning',
                'Dedicated sanctuary spaces'
            ],
        },
    },
];

export default projects;

