import React from 'react';
import './WhyUs.css';

import { images } from '../../../constants';

import { FaCheck } from 'react-icons/fa';

   
 
const WhyUs = () => {
  
  return (  
    <section className='why-us'>

    <div className='box-container'>

      <div className='content'>
        {/* Heading */}
        <div className='heading'>  
          <span>why choose us</span> 
          <h3>We have Solutions for Agriculture Need.</h3>
        </div>
        {/* Description */}
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde quaerat illum mollitia esse quidem quas, iure excepturi vel, quam dolore voluptatum incidunt consequuntur natus doloremque. Facere assumenda ut id exercitationem?</p>
        {/* List of reasons */}
        <ul> 
          <li><FaCheck className='icon' />Qualified Workers</li>
          <li><FaCheck className='icon' />24/7 availability</li>
          <li><FaCheck className='icon' />best material</li>
          <li><FaCheck className='icon' />formula method</li>
          <li><FaCheck className='icon' />reasonable price</li>
          <li><FaCheck className='icon' />satisfaction guarantee</li>
        </ul>
      </div>

      {/* Image */}
      <div className='image'>
        <img src={images.whyUs} alt="WhyUs-Pic" />
      </div> 

    </div>
  
  </section>
  )
  
}

export default WhyUs;