import React from 'react';

import { PageTitle, Project } from '../../../components';



const ProjectPage = () => {
 
  return ( 
    <>  
      {/* Page Title */}
      <PageTitle title={'Projects'} page={'Projects'} />

      {/* Project Section */}
      <section> 
        <Project />
      </section>
    </>
  );
  
}

export default ProjectPage;