import { images } from '.';

const team = [  
    {
        id: 1,
        image: images.team1,
        name: 'Chris Harris',
        title: 'Founder & CEO',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#', 
            instagram: '#',
        },
        skills: [
            {id: 1, title: 'Creative Design', progress: 95},
            {id: 2, title: 'Customer Service', progress: 90},
            {id: 3, title: 'Interior Design', progress: 85},
            {id: 4, title: 'Exterior Design', progress: 75},
            {id: 5, title: 'Architecture', progress: 65},
            {id: 6, title: 'Construction', progress: 60},
        ],
    }, 
    {
        id: 2,
        image: images.team2,
        name: 'Lisa Astle',
        title: 'Senior architect',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        skills: [
            {id: 1, title: 'Creative Design', progress: 95},
            {id: 2, title: 'Customer Service', progress: 90},
            {id: 3, title: 'Interior Design', progress: 85},
            {id: 4, title: 'Exterior Design', progress: 75},
            {id: 5, title: 'Architecture', progress: 65},
            {id: 6, title: 'Construction', progress: 60},
        ],
    }, 
    {
        id: 3,
        image: images.team3,
        name: 'Justin Vaughan',
        title: 'Interior Designer',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        skills: [
            {id: 1, title: 'Creative Design', progress: 95},
            {id: 2, title: 'Customer Service', progress: 90},
            {id: 3, title: 'Interior Design', progress: 85},
            {id: 4, title: 'Exterior Design', progress: 75},
            {id: 5, title: 'Architecture', progress: 65},
            {id: 6, title: 'Construction', progress: 60},
        ],
    }, 
    {
        id: 4,
        image: images.team4,
        name: 'Kelly Brown',
        title: 'Exterior Designer',
        biography: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in! Lorem ipsum dolor sit amet consectetur adipisicing elit. A eveniet possimus quos corrupti neque explicabo iure dolore nobis impedit obcaecati iste, debitis dignissimos doloribus reiciendis temporibus perspiciatis libero quam ipsa. Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus voluptatem rem deleniti modi suscipit odio itaque. Nostrum quae autem quidem est voluptas neque dolores, atque deleniti id, fuga, impedit in!',
        accounts: {
            facebook: '#',
            linkedin: '#',
            twitter: '#',
            instagram: '#',
        },
        skills: [
            {id: 1, title: 'Creative Design', progress: 95},
            {id: 2, title: 'Customer Service', progress: 90},
            {id: 3, title: 'Interior Design', progress: 85},
            {id: 4, title: 'Exterior Design', progress: 75},
            {id: 5, title: 'Architecture', progress: 65},
            {id: 6, title: 'Construction', progress: 60},
        ],
    }, 
];

export default team;