import { images } from '../constants';

const home = [
    {    
        id: 1,
        image: images.home1,
        title: 'Giving your Home a new Style',
        content: 'We provide quality construction for every project. Your satisfaction is our goal. Your vision is our business. We build the house. You make it a home.',
    },
    {
        id: 2,
        image: images.home2,
        title: 'Good Design for Good Home',
        content: 'We provide quality construction for every project. Your satisfaction is our goal. Your vision is our business. We build the house. You make it a home.', 
    },
    { 
        id: 3,
        image: images.home3,
        title: 'Building the way you desire',
        content: 'We provide quality construction for every project. Your satisfaction is our goal. Your vision is our business. We build the house. You make it a home.',     
    },

];

export default home;