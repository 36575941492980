import React from 'react';
import './Heading.css';

import { images } from '../../constants';

 

const Heading = (props) => {

  // Destructuring props
  const { title, subTitle } = props;
 
  return (    
 
    <div className='heading'>

      <span>{subTitle}</span> {/* Heading Sub-Title */}
      <h2>{title}</h2> {/* Heading Title */}
      <img src={images.heading} alt='heading-image' /> {/* Heading Image */}

    </div>
    
  );

};

export default Heading;