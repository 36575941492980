import React from 'react';

import { PageTitle } from '../../../components';
import { Appointment } from '../../../sections';
 
   

const AppointmentPage = () => { 

  return (
    <>
      {/* Page Title */}
      <PageTitle title={'Appointment'} page={'Appointment'} />

      {/* Appointment Section */}
      <Appointment />
    </> 
  );
}

export default AppointmentPage;