import React from 'react';
import './Pricing.css';

import { Heading } from '../../../components';
import { pricing } from '../../../constants';

import { FaRegCircle } from 'react-icons/fa';
 
  

const Pricing = () => {  
  return (  
    <section className='pricing' id='pricing'>

      {/* Heading for the Pricing section */}
      <Heading title={'pricing plan'} subTitle={'choose your plan'} />

      <div className='box-container'> 
        { 
          // Map over the pricing array and render a price-item for each pricing plan
          pricing.map((item) => {
            const listItems = item.list;
            return( 
              <div className='price-item' key={item.id}>
                <div className='intro'>
                  <h3>{item.title}</h3> {/* Pricing plan Title */}
                  <p>{item.content}</p> {/* Pricing plan Content */}
                  <div className='price'>${item.price}<span>/month</span></div> {/* Pricing plan Price */}
                </div>
                <ul>
                  {
                    // Map over the list of features for the pricing plan and render each feature
                    listItems.map((list, index) => {
                      return <li key={index}>
                        <div className='icon'><FaRegCircle /></div> {/* Display an icon for each feature */}
                        {list}
                      </li>
                    })
                  }
                </ul>
                {/* Display a "choose plan" button for each pricing plan */}
                <a href='#' className='btn'>choose plan</a>
              </div>
            )
          })
        }
      </div>

    </section>
  )
}

export default Pricing;