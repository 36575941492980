import { useState } from "react";

// Custom hook to manage the wishlist state and operations
export const useWishlist = () => {

  // State to store the wishlist items
  const [wishlist, setWishlist] = useState([]);

  /* 1- Add an item to the wishlist */
  const addToWishlist = (item) => {

    // Check if the item already exists in the wishlist
    const isItemExist = wishlist.some((wishlistItem) => wishlistItem.id === item.id);

    // Add the item to the wishlist if it doesn't already exist
    if (!isItemExist) {
      // Get the current date and time
      const options = { month: 'long', day: 'numeric', year: 'numeric' };
      const currentDate = new Date().toLocaleDateString('en-US', options);

      // Create a new item with the added date and time
      const newItem = { ...item, dateAdded: currentDate };

      // Update the wishlist state by adding the new item
      setWishlist([...wishlist, newItem]);
    }

  };

  /* 2- Delete item from the wishlist */
  const removeFromWishlist = (item) => {
    // Remove the item from the wishlist based on its ID
    setWishlist(wishlist.filter((wishlistItem) => wishlistItem.id !== item.id));
  };

  // Return the wishlist state and operations
  return { wishlist, setWishlist, addToWishlist, removeFromWishlist };

};