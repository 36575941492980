import React, { useState, useEffect } from 'react';
import './Header.css';

import { HeaderItem, MobileNavbar, Logo } from '../../components';
import { navbar } from '../../constants';

import { FaPhoneAlt, FaEnvelope, FaFacebookF, FaTwitter, FaInstagram, FaLinkedin, FaRegUser, FaCartArrowDown, FaBars, FaTimes, FaSearch } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
 

   
const Header = (props) => { 

  const { cart } = props; 

  const navigate = useNavigate(); 
  const { pathname } = useLocation();

  const [click, setClick] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [searchValue, setSearchValue] = useState('');


  /********** 1-Manage Sticky Header ***********/
  // Manage Sticky Header 
  useEffect(() => {
    window.addEventListener('scroll', stickyHeader);
    return () => {
      window.removeEventListener('scroll', stickyHeader);
    };
  });

  // Add active class to header on scroll
  const stickyHeader = (e) => {
    const header = document.querySelector('.header');
    const scrollTop = window.scrollY;
    scrollTop >= 60 ? header.classList.add('active') : header.classList.remove('active');
  };

  /********** 2-Manage On Scroll  ***********/
  // Manage On Scroll Functionality
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  // Handle scroll event
  const handleScroll = (e) => {
    /* Close Menubar On Scroll */
    const navbar = document.querySelector('.navbar');
    navbar.classList.remove('active');
    setClick(false);
    setOpen(false);
    setIsActive(false);
  };
  
  /********** 3-Manage Mobile Navbar ***********/
  // Handle click on menu button
  const handleClick = () => { 
    setIsActive(false);
    setClick((prevClick) => !prevClick);
    setOpen((prevOpen) => !prevOpen);
  };

  // Close the Navbar between navigation
  useEffect(() => {
    setOpen(false);
    setClick(false);
    setIsActive(false);
  }, [pathname]);

  /********** 4-Manage Search Functionality ***********/
  // Function to handle search icon click
  const handleSearchIconClick = () => {
    setIsActive(!isActive);
    if (!isActive) {
      setSearchValue('');
    }
  }; 

  // Function to handle search form submit
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchValue.trim() !== '') {
      navigate(`/blogs/search/${searchValue}`);
      setSearchValue(''); // Reset the search value to clear the input field
    }
  };

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  
  return (
    <nav className='header'>

      {/* 1st Header Container */}
      <div className='header-1'>

        {/* Header contacts */}
        <ul className='header-contacts'>
          <li><FaPhoneAlt className='icon' /><span>+111-222-333</span></li>
          <li><FaEnvelope className='icon' /><span className='gmail'>abc@gmail.com</span></li>
        </ul>

        {/* Social contacts */}
        <div className='social-contacts'>
          <a href="/#"><FaFacebookF className='icon' /></a>
          <a href="/#"><FaTwitter className='icon' /></a>
          <a href="/#"><FaInstagram className='icon' /></a>
          <a href="/#"><FaLinkedin className='icon' /></a>
        </div>

      </div>

      {/* 2nd Header Container */}
      <div className='header-2'>

        <div className='icon menu-btn' onClick={handleClick}>{click ? <FaTimes /> : <FaBars />}</div> {/* Menu Button */}

        {/* Logo */}
        <Logo />

        {/* Mobile Navbar */}
        <MobileNavbar isOpen={isOpen} />
 
        <div className='container'>

          {/* Desktop Navbar */}
          <div className='navbar'>
            {
              navbar.map((item) => {
                return <HeaderItem key={item.id} {...item} />
              })
            } 
          </div>  

          {/* Header Icons Container */}
          <div className='icon-container'>
            <div id="search" className={`icon fa-solid fa-magnifying-glass ${isActive ? 'active' : ''}`} onClick={handleSearchIconClick}><FaSearch className='icon' /></div>
            <Link to='/Login'><FaRegUser className='icon' /></Link> {/* Login Link */}
            <Link to='/Cart' className='cart-icon'><FaCartArrowDown className='icon' /><span className='badge'>{cart.length}</span></Link> {/* Cart Link */}
          </div>

        </div>

        {/* Search Container */}
        <form onSubmit={handleSearchSubmit} className={`search-container ${isActive ? 'active' : ''}`}>
          <input type='text' id='search' placeholder='Search here...' value={searchValue} onChange={handleSearchChange} required /> {/* Search Input */}
          <button type="submit"><FaSearch className='icon' /></button> {/* Search Button */}
        </form>
        
      </div>

    </nav>
  );
}

export default Header;