import React from 'react';

import { Home, About, Counter, Testimonials, Blogs, Sponsors, Services, Team, Appointment, Pricing, WhyUs, ProjectSection } from '../../sections';

 

const HomePage = () => {

  return (
    <div className='homepage'>
      <Home /> {/* Home Section */}
      <About /> {/* About Section */} 
      <Services /> {/* Services Section */}
      <Counter /> {/* Counter Container */}
      <ProjectSection /> {/* Project Section */} 
      <WhyUs /> {/* Why Us Section */} 
      <Testimonials /> {/* Testimonials Section */}
      <Team /> {/* Team Section */}
      <Pricing /> {/* Pricing Section */}
      <Appointment /> {/* Appointment Section */}
      <Blogs /> {/* Blogs Section */}
      <Sponsors /> {/* Sponsors Section */} 
    </div>
  )
}

export default HomePage;