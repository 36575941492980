import React, { useState } from 'react';
import './Faqs.css';

import { PageTitle, FaqItem } from '../../../components';
import { faqsContent, images } from '../../../constants';

 
 
const Faqs = () => { 
 
  // State to manage the active accordion for container 1
  const [activeAccordionId, setActiveAccordionId] = useState(faqsContent[0].id);

  // Function to handle accordion click event for container 1
  const handleAccordionClick = (id) => {
    setActiveAccordionId((prevId) => (prevId === id ? null : id));
  };

  
  return (
    <> 
      {/* Page title */}
      <PageTitle title={'faqs'} page={'faqs'} />

      <section className='faq'>

        {/* Faq Accordion container */}
        <div className='accordion-container'>
          {/* Faq items */}
          {faqsContent.map((faq) => {
            return (            
              <FaqItem
                key={faq.id}
                id={faq.id}
                title={faq.heading}
                content={faq.content}
                isActive={faq.id === activeAccordionId}
                onAccordionClick={handleAccordionClick}
              /> )
          })}
        </div>

        {/* Faq Image */}
        <div className='image'>
          <img src={images.FAQs} alt='About-Pic' /> 
        </div>
        
      </section>
    </>
  );
};

export default Faqs;