import React from 'react';

import { Heading, Project } from '../../../components';

  
 
const ProjectSection = () => {
  return (

    <section>  
      {/* Section heading */}
      <Heading title={'our projects'} subTitle={'our recent work'} />
      {/* Project Component */}
      <Project />
    </section>

  ); 
}

export default ProjectSection;