import React from 'react';

import { PageTitle } from '../../../components';
import { Team } from '../../../sections';



const TeamPage = () => { 
 
  return (    
    <> 
      {/* Page title */} 
      <PageTitle title={'team'} page={'team'} />

      {/* Team section */}
      <Team />
    </>
  )
  
}

export default TeamPage;