import { FaEnvelope, FaMapMarkedAlt, FaClock, FaPhoneAlt } from 'react-icons/fa';

const contacts = [  
    { 
        id: 1, 
        icon: <FaPhoneAlt />,
        title: 'call us',
        content: [ '+111-222-333'],
    },
    {
        id: 2,
        icon: <FaEnvelope />,
        title: 'email us',
        content: [ 'abc@gmail.com'],
    },
    {
        id: 3,
        icon: <FaMapMarkedAlt />,
        title: 'office address',
        content: ['karachi, pakistan'],
    },
    {
        id: 4,
        icon: <FaClock />,
        title: 'open timing',
        content: ['Mon - Fri (8AM - 10PM)'],
    },
 
];

export default contacts;