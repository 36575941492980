import React from 'react';
import './ProjectItem.css';

import { FaCamera } from 'react-icons/fa';
import { Link } from 'react-router-dom';
 
  

const ProjectItem = (props) => {  
 
  // Destructuring props
  const { id, image, title, category, popup, project } = props;
 
  return (
    <div className='project-item' key={id}>
 
      {/* Project image */} 
      <img src={image} alt={title} /> 
 
      {/* Project Content */} 
      <div className='content'> 

        {/* Project Category */}
        <h6>{category}</h6>

        {/* Link to the individual Project Page */}
        <Link
          to={{
            pathname: '/Projects/' + title,
          }}
          state={project}
          className='main-heading' >
          <h4>{title}</h4> {/* Project Title */}
        </Link>

        {/* Trigger popup function */}
        <a onClick={() => {popup(id)}}><FaCamera className='icon' /></a>  

      </div> 

    </div>  
  ) 

}

export default ProjectItem;