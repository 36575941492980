import React from 'react';

import { PageTitle } from '../../../components';
import { Services } from '../../../sections';



const ServicePage = () => {  

  return (  
    <>  

      {/* Page Title */}
      <PageTitle title={'Services'} page={'Services'} />

      {/* Services Section */}
      <Services />

    </>
  )
  
}

export default ServicePage;