import React from 'react';
import './ProjectSingle.css';

import { Lightbox, PageTitle } from '../../../components';

import { FaCheck, FaCircleNotch, FaCalendarAlt, FaUserAlt, FaListUl, FaClock, FaUserCog, FaDollarSign, FaGlobe, FaMap } from 'react-icons/fa';

import { useLocation } from 'react-router-dom';

   
  
const ProjectSingle = () => { 

  const location = useLocation();  
  const projectSingle = location.state; 

  return (
    <>
      {/* Page Title */}
      <PageTitle title={'project single'} page={'project single'} />

      <section className='project-single'>

        {/* Project Details */}
        <div className='project-details'>

          <h3 className='sub-heading'>Project Info</h3> 
          
          {/* Project Details Container */}
          <div className='container'>

            {/* Date */}
            <div className='detail-item'>
              <FaCalendarAlt className='icon' />
              <div className='content'>
                <h3>date:</h3>
                <p>{projectSingle.date}</p>
              </div>
            </div>

            {/* Client */}
            <div className='detail-item'>
              <FaUserAlt className='icon' />
              <div className='content'>
                <h3>client:</h3>
                <p>{projectSingle.client}</p>
              </div>
            </div>

            {/* Category */}
            <div className='detail-item'>
              <FaListUl className='icon' />
              <div className='content'>
                <h3>category:</h3>
                <p>{projectSingle.category}</p>
              </div>
            </div>

            {/* Duration */}
            <div className='detail-item'>
              <FaClock className='icon' />
              <div className='content'>
                <h3>duration:</h3>
                <p>{projectSingle.duration}</p>
              </div>
            </div>

            {/* Location */}
            <div className='detail-item'>
              <FaMap className='icon' />
              <div className='content'>
                <h3>location:</h3>
                <p>{projectSingle.location}</p>
              </div>
            </div>

            {/* Website */}
            <div className='detail-item'>
              <FaGlobe className='icon' />
              <div className='content'>
                <h3>website:</h3>
                <p className='website'>{projectSingle.website}</p>
              </div>
            </div>

            {/* Budget */}
            <div className='detail-item'>
              <FaDollarSign className='icon' />
              <div className='content'>
                <h3>budget:</h3>
                <p>{projectSingle.budget}</p>
              </div>
            </div>

            {/* Architect */}
            <div className='detail-item'>
              <FaUserCog className='icon' />
              <div className='content'>
                <h3>architect:</h3>
                <p>{projectSingle.architect}</p>
              </div>
            </div>

          </div>

        </div>

        <div className='project-info'>

          {/* Project Image */} 
          <div className='image'>
            <img src={projectSingle.image} alt='Blog' />
          </div>

          <div className='content'>

            <h3 className='main-heading'>{projectSingle.title}</h3> {/* Project heading */}

            <p className='important'>"{projectSingle.important}"</p> {/* Project important intro */}

            {/* Project Content */}
            {projectSingle.paragraphs.map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}

          </div>

          {/* Project Image gallery */}
          <Lightbox images={projectSingle.gallery} className={'project'} />

          {/* Section for project challenges */}
          <div className='sub-section'>
            <h3 className='sub-heading'>project challenges:</h3> 
            <p>{projectSingle.challenges.intro}</p>
            <ul>
              {/* List of project challenges */}
              {projectSingle.challenges.points.map((challenge, index) => {
                return <li key={index}><FaCheck className='icon' />{challenge}</li>
              })}
            </ul>
          </div>

          {/* Section for project results */}
          <div className='sub-section'>
            <h3 className='sub-heading'>project results:</h3>
            <p>{projectSingle.results.intro}</p>
            <ul>
              {/* List of project results */}
              {projectSingle.results.points.map((result, index) => {
                return <li key={index}><FaCircleNotch className='icon' />{result}</li>
              })}
            </ul>
          </div>
        
        </div> 

      </section> 
    </> 
  );
}

export default ProjectSingle;
